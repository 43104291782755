<template>
    <div class="select-container">
        <div class="inner-content">
            <div class="big-title">商品库</div>
            <div class="select-box">
                <el-table :data="infoList"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#fff',fontSize: '14px',background:'#0C0E3F'}"
                          :cell-style="{fontSize: '14px',color: '#fff',height: '80px',background:'#070932'}">
                    <el-table-column prop="name" label="排列序号" width="150">
                        <div class="table-index" slot-scope="scope">{{scope.$index + 1}}</div>
                    </el-table-column>
                    <el-table-column label="商品信息" width="200">
                        <router-link :to="{path: '/goodDetail', query: {id: scope.row.goods_id}}" target="_blank" class="good-info" slot-scope="scope">
                            <div class="good-img">
                                <img :src="scope.row.getGoodsInfo.goods_master_img" alt="">
                            </div>
                            <div class="good-title" :title="scope.row.getGoodsInfo.goods_name">
                                <div class="text-overflow">
                                    {{scope.row.getGoodsInfo.goods_name}}
                                </div>
                            </div>
                        </router-link>
                    </el-table-column>
                    <el-table-column prop="goods_sku" label="商品SKU">
                        <template slot-scope="scope">
                            <span>{{scope.row.getGoodsInfo.goods_sku}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="日常价">
                        <template slot-scope="scope">
                            &yen;{{scope.row.getGoodsInfo.goods_market_price}}
                        </template>
                    </el-table-column>
                    <el-table-column label="直播间价">
                        <template slot-scope="scope">
                            &yen;{{scope.row.getGoodsInfo.goods_discount_price}}
                        </template>
                    </el-table-column>
                    <el-table-column label="佣金比例(利润率）" width="140">
                        <template slot-scope="scope">
                            {{scope.row.getGoodsInfo.goods_commission_rate}}%
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <router-link :to="{path: '/live/selection/detail', query: {id: scope.row.goods_id}}"
                                         target="_blank"
                                         class="see-btn">查看数据
                            </router-link>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination style="padding: 20px 0; text-align: center"
                               background
                               :current-page="listPages.currentPageNum"
                               :page-size="listPages.eachPageNum"
                               layout="prev, pager, next, jumper"
                               :total="listPages.total"
                               @current-change="pageCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {live_script_goods} from '@/utils/apis'

    export default {
        data() {
            return {
              role: Number(localStorage.getItem('role')),
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
          if (this.role === 4) {
            this.infoList = [
              {
                "id": 933,
                "goods_id": 96,
                "script_id": 242,
                "template_id": 0,
                "start_time": 57,
                "end_time": 247,
                "delete_time": 0,
                "introduce_count": 4,
                "getGoodsInfo": {
                  "id": 96,
                  "goods_name": "至本多元优效精华露",
                  "goods_market_price": "168.00",
                  "goods_discount_price": "158.00",
                  "goods_sku": "1258",
                  "goods_commission_rate": 10,
                  "goods_master_img": "uploads/system/1/goods/20220222/d3b05a4d30d9e4cd089836749e447e29.jpg"
                }
              },
              {
                "id": 934,
                "goods_id": 91,
                "script_id": 242,
                "template_id": 1,
                "start_time": 247,
                "end_time": 437,
                "delete_time": 0,
                "introduce_count": 6,
                "getGoodsInfo": {
                  "id": 91,
                  "goods_name": "至本特安修护面霜",
                  "goods_market_price": "98.00",
                  "goods_discount_price": "88.00",
                  "goods_sku": "1244",
                  "goods_commission_rate": 2,
                  "goods_master_img": "uploads/system/1/goods/20220218/21ce0d30527926d70a95588d7c1e8753.jpg"
                }
              },
              {
                "id": 935,
                "goods_id": 89,
                "script_id": 242,
                "template_id": 2,
                "start_time": 437,
                "end_time": 627,
                "delete_time": 0,
                "introduce_count": 5,
                "getGoodsInfo": {
                  "id": 89,
                  "goods_name": "至本舒颜修护卸妆膏",
                  "goods_market_price": "59.00",
                  "goods_discount_price": "55.00",
                  "goods_sku": "1234",
                  "goods_commission_rate": 2,
                  "goods_master_img": "uploads/system/1/goods/20220217/805ee8603fd13d05d979d5b163eb7479.jpg"
                }
              },
              {
                "id": 936,
                "goods_id": 88,
                "script_id": 242,
                "template_id": 3,
                "start_time": 627,
                "end_time": 817,
                "delete_time": 0,
                "introduce_count": 4,
                "getGoodsInfo": {
                  "id": 88,
                  "goods_name": "至本舒颜修护洁面乳",
                  "goods_market_price": "60.00",
                  "goods_discount_price": "55.00",
                  "goods_sku": "12233",
                  "goods_commission_rate": 2,
                  "goods_master_img": "uploads/system/1/goods/20220307/ef302cf5a538f777a5e1cde9e3ac10c3.jpg"
                }
              }
            ]
          } else {
            this.getList()
          }
        },
        methods: {
            getList() {
                let script_id = localStorage.getItem('scriptId')
                let param = {
                    script_id: script_id,
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                live_script_goods(param).then(res => {
                    if (res.code === 200) {
                        this.infoList = res.data.list
                        this.listPages.total = res.data.total
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .select-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .inner-content {
        margin: 20px;
        flex: 1;
        height: 1%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .big-title {
        line-height: 1;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .select-box {
        height: 1%;
        flex: 1;
    }

    .table-index {
        font-size: 14px;
    }

    .good-info {
        display: flex;
        align-items: center;

        .good-img {
            width: 60px;
            height: 60px;
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f6f6f6;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .good-title {
            margin-left: 10px;
            width: 1%;
            flex: 1;

            .text-overflow {
                color: #fff;
            }
        }
    }

    .see-btn {
        display: inline-block;
        padding: 10px;
        color: #2821fc;
        font-weight: 500;
        white-space: nowrap;

        &:hover {
            color: #534dfd;
        }
    }

    .el-table {
        /*position: absolute;*/
        width: 100%;

        &:before {
            content: none;
        }

        ::v-deep .el-table__empty-block {
            background: #070932;

            .el-table__empty-text {
                color: #fff;
            }
        }

        ::v-deep .el-table__body-wrapper {
            background: #070932;
        }


        ::v-deep th.el-table__cell.is-leaf {
            border-bottom: none;
        }

        ::v-deep td.el-table__cell {
            border-bottom: 1px solid #262963;

            &:first-child {
                border-left: 1px solid #262963;
            }

            &:last-child {
                border-right: 1px solid #262963;
            }
        }
    }
</style>